import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import Overlay from '../components/atoms/overlay';
import { ease } from '../utils/easing';
import { useWindowSize } from '../hooks/useWindowSize';
import Cursor from '../components/atoms/cursor';

const PixPage = ({ data }) => {
  const { image } = data.pix.nodes[0];
  const size = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);
  const [currImg, setCurrImg] = useState(null);
  const [overlayActive, setOverlayActive] = useState(false);
  const [imgHover, setImgHover] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 768);
  }, [size, imgHover]);

  return (
    <>
      <SEO siteTitle="Pix" />
      {!isMobile && (
        <Cursor text={imgHover ? 'Show' : overlayActive ? 'Close' : ''} />
      )}
      <StyledPix>
        <div className="images">
          {image.map((item, i) => (
            <div
              key={i}
              className={`img-wrapper ${item.layout ? item.layout : ''}`}
            >
              <figure
                onClick={() => {
                  setCurrImg(i);
                  setOverlayActive(true);
                }}
                onMouseOver={() => setImgHover(true)}
                onMouseLeave={() => setImgHover(false)}
              >
                <GatsbyImage
                  image={item.image.asset.gatsbyImageData}
                  alt={item.alt}
                />
              </figure>
            </div>
          ))}
        </div>
        {overlayActive && (
          <Overlay
            active={overlayActive}
            onClick={() => {
              setOverlayActive(false);
              setCurrImg(null);
            }}
            style={{ zIndex: 0, cursor: 'none' }}
          />
        )}
        <motion.div
          className="overlay-img"
          animate={{
            opacity: overlayActive ? 1 : 0,
            scale: overlayActive ? 1 : 0.95,
          }}
          transition={{ duration: 0.2, ease: ease.outSmooth }}
        >
          <figure>
            <GatsbyImage
              imgStyle={{ objectFit: 'contain' }}
              image={image[currImg]?.image.asset.gatsbyImageData}
              alt={image[currImg]?.alt}
            />
          </figure>
        </motion.div>
      </StyledPix>
    </>
  );
};

const StyledPix = styled.section`
  padding: calc(var(--sp-1-3) - calc(var(--sp-0) / 2));
  margin-top: var(--sp-5);

  .overlay-img {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;

    figure {
      height: 100vh;
      width: 100vw;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;

    .img-wrapper {
      width: calc(100% / 3);
      height: calc(97vw / 3);
    }

    .onethirdleft,
    .onethirdright,
    .twothirdleft,
    .twothirdright {
      width: 100%;
      height: auto;
    }

    .onethirdleft,
    .onethirdright {
      figure {
        width: calc(100% / 3);
      }
    }

    .twothirdleft,
    .twothirdright {
      figure {
        width: calc(100% / 3 * 2);
      }
    }

    .onethirdright,
    .twothirdright {
      display: flex;
      justify-content: flex-end;
    }

    figure {
      padding: calc(var(--sp-0) / 2);
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: opacity 0.3s;
      cursor: none;

      &:hover {
        opacity: 0.7;
      }

      &.onethirdleft {
        width: 100%;
        height: auto;

        .gatsby-image-wrapper {
          width: calc(100% / 3.01 - var(--sp-0));
        }
      }

      &.twothirdright {
        width: calc(100% / 2);
        height: auto;

        .gatsby-image-wrapper {
          width: calc(100% / 2 - var(--sp-0));
        }
      }
    }
  }

  @media ${media.L} {
    padding: calc(var(--sp-1-3) - var(--sp-0));

    .images {
      figure {
        padding: var(--sp-0);
      }
    }
  }
`;

export const query = graphql`
  query Pix {
    pix: allSanityPix {
      nodes {
        image {
          alt
          layout
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 2400)
            }
          }
        }
      }
    }
  }
`;

PixPage.propTypes = {
  data: PropTypes.object,
};

export default PixPage;
